.productCoverImageSlider {
  position: relative;
  background-color: #f7f7f7;
  max-width: 600px;
}
.productCoverImageSlider__viewport {
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1;
}
.productCoverImageSlider__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.productCoverImageSlider__slide {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  height: 100%;
  transition: transform 0.2s;
}
.productCoverImageSlider__slide:hover {
  transform: scale(1.2);
}

.productCoverImageSlider__button {
  outline: 0;
  cursor: pointer;
  touch-action: manipulation;
  position: absolute;
  z-index: 6;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  color: #ffffff;
  background-color: #000;
  opacity: 0.2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.productCoverImageSlider__button svg {
  font-size: 33px;
}

.productCoverImageSlider__button:disabled {
  cursor: not-allowed;
  opacity: 0.1;
}

.productCoverImageSlider__prev {
  left: 0px;
}

.productCoverImageSlider__next {
  right: 0px;
}

/* --------------------------------------------------------------------------------- */
.bestsellers-list {
  position: relative;
}
.bestsellers-list__viewport {
  overflow: hidden;
}
.bestsellers-list__container {
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 45%;
  margin-left: -10px;
}
.bestsellers-list__slide {
  padding-left: 10px;
}

.bestsellers-list__button {
  outline: 0;
  cursor: pointer;
  touch-action: manipulation;
  position: absolute;
  z-index: 6;
  top: 45%;
  transform: translateY(-50%);
  border: 0;
  color: #ffffff;
  background-color: #000;
  opacity: 0.2;
  width: 40px;
  aspect-ratio: 0.7;
  display: none;
}
.bestsellers-list__button svg {
  font-size: 33px;
}
.bestsellers-list__prev {
  left: -40px;
}
.bestsellers-list__next {
  right: -40px;
}

/* mantine sm breakpoint */
@media (min-width: 768px) {
  .bestsellers-list__container {
    grid-auto-columns: 40%;
  }
}

/* mantine md breakpoint */
@media (min-width: 992px) {
  .bestsellers-list__container {
    grid-auto-columns: 30%;
  }
}
/* mantine lg breakpoint */
@media (min-width: 1200px) {
  .bestsellers-list__container {
    grid-auto-columns: 25%;
  }
  .bestsellers-list__button {
    display: block;
  }
}
